<script lang="ts" setup>
defineProps<{
  class?: string;
}>();
const activeLinks = useActiveLinks();
</script>

<template>
  <nav :class="cn($props.class)">
    <ul class="flex gap-1 items-center">
      <li
        v-for="link in activeLinks"
        :key="link.to"
        class="after:content-['/'] after:ml-1 last:after:content-none"
      >
        <NuxtLink :to="link.to" class="text-neutral-6 text-xs xs:text-sm hover:text-neutral-8">{{
          $t(link.title)
        }}</NuxtLink>
      </li>
    </ul>
  </nav>
</template>
