import { useQuery } from "@tanstack/vue-query";

import type { GqlErrorHandlingOptions } from "../useGqlErrorHandling.js";

type Options = {
  errorHandling: GqlErrorHandlingOptions;
};

export default function useCategoriesGet(options?: Options) {
  async function queryFn() {
    const res = await GqlGetCategories({});
    return res.categories;
  }

  const context = useQuery({
    queryKey: [QUERY_KEYS.categories],
    queryFn
  });
  useGqlErrorHandling(context.error, context.data, options?.errorHandling);
  return context;
}
