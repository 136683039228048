<script lang="ts" setup>
import { Icon } from "@iconify/vue";

import type { Category } from "~/types";

const { minPowerLevel = UserRole.Junior, selectedCategories } = defineProps<{
  selectedCategories?: Category[];
  class?: string;
  isPending?: boolean;
  minPowerLevel?: Exclude<UserRole, UserRole.Aspirant>;
}>();

const emit = defineEmits<{
  (e: "add", value: Category): void;
  (e: "remove", value: string): void;
}>();

const selected = ref<string | undefined>(undefined);

const hasAccess = useHasAccess();

const { data: allCategories } = useCategoriesGet();
const { locale } = useI18n();
const label = computed(() => (locale.value === "de" ? "labelDe" : "labelEn"));

const selectedCategoriesSorted = computed(() => {
  return selectedCategories
    ?.map((category) => ({ name: category.name, label: category[label.value] }))
    .sort((a, b) => a.label.localeCompare(b.label));
});

const unselectedCategories = computed(
  () =>
    allCategories.value
      ?.filter(
        (category) => !selectedCategoriesSorted.value?.find((selected) => selected.name === category.name)
      )
      .map((category) => ({ label: category[label.value], value: category.name }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? []
);

function onAdd(selectedCategoryName: string) {
  const category = allCategories.value?.find((category) => category.name === selectedCategoryName);
  if (!category) throw Error(`Category ${selectedCategoryName} not found`);
  emit("add", category);
  selected.value = "";
}
function onRemove(categoryName: string) {
  emit("remove", categoryName);
  selected.value = "";
}
</script>

<template>
  <ul v-auto-animate :class="cn('flex flex-wrap gap-1', $props.class)">
    <li v-for="category in selectedCategoriesSorted" :key="category.name" class="relative">
      <BasicButton
        size="sm"
        variant="white"
        :disabled="!hasAccess[minPowerLevel]"
        class="shrink-0 uppercase tracking-wider [&:hover_svg]:opacity-100 [&:focus_svg]:opacity-100 [&:focus_*]:bg-neutral-0 [&:focus_*]:text-neutral-8 disabled:!opacity-100"
        @click="onRemove(category.name)"
      >
        {{ category.label }}
        <Icon
          v-if="hasAccess[minPowerLevel]"
          icon="lucide-minus"
          :class="
            cn(
              'block !size-4 bg-neutral-3 absolute -top-0.5 -right-0.5',
              'opacity-0 transition rounded-full',
              'border border-neutral-5'
            )
          "
        />
      </BasicButton>
    </li>
    <li :key="selected">
      <BasicSelect
        v-if="hasAccess.senior"
        v-model="selected"
        :options="unselectedCategories"
        :placeholder="!selectedCategories?.length ? $t('category.select') : undefined"
        size="sm"
        class="shrink-0 text-nowrap text-xs tracking-wider uppercase"
        :selectValue="false"
        :icon="isPending ? ICONS.loader : ICONS.add"
        @update:modelValue="onAdd"
      />
    </li>
  </ul>
</template>
